<template>
  <b-row class="justify-content-center no-gutters">
    <b-col cols="10" md="12" lg="10" xl="10" sm="12" xs="12">
      <div class="divider mt-5">
        <div class="divider-text" style="font-size: 1.5em">Social Media</div>
      </div>

      <div class="auth-footer-btn d-flex justify-content-center mb-5">
        <b-button
          variant="link"
          href="https://www.linkedin.com/company/amg-human-talent-management"
          target="_blank"
        >
          <img
            :src="linkedin"
            alt="Linkedin"
            style="min-width: 40px; max-width: 40px"
          />
        </b-button>
        <b-button
          variant="link"
          href="https://www.instagram.com/amg.peru/"
          target="_blank"
        >
          <img
            :src="instagram"
            alt="Instagram"
            style="min-width: 40px; max-width: 40px"
          />
        </b-button>
        <b-button
          variant="link"
          href="https://www.tiktok.com/@amg.peru?_t=8aJzJZ1ew5u&_r=1"
          target="_blank"
        >
          <img
            :src="tiktok"
            alt="TikTok"
            style="min-width: 40px; max-width: 40px"
          />
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import store from "@/store/index";

export default {
  name: "SocialNetwork",
  data() {
    return {};
  },
  computed: {
    theme() {
      return store.state.appConfig.layout.skin === "dark" ? "dark" : "light";
    },
    linkedin() {
      return this.theme === "dark"
        ? require("@/assets/images/icons/linkedin_dark.svg")
        : require("@/assets/images/icons/linkedin_light.svg");
    },
    instagram() {
      return this.theme === "dark"
        ? require("@/assets/images/icons/instagram_dark.svg")
        : require("@/assets/images/icons/instagram_light.svg");
    },
    tiktok() {
      return this.theme === "dark"
        ? require("@/assets/images/icons/tiktok_dark.svg")
        : require("@/assets/images/icons/tiktok_light.svg");
    },
  },
};
</script>