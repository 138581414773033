<template>
  <b-col lg="7" class="d-none d-lg-flex align-items-center px-5 bg-login">
    <video autoplay muted loop class="login-video">
      <source
        :src="require('@/assets/videos/BANNER FORMATO 3.mp4')"
        type="video/mp4"
      />
    </video>

    <div class="w-100 d-lg-flex align-items-center px-5">
      <span class="text-login text-left">
        <p class="mb-2 principal-text">We work with</p>
        <p class="secondary-text">the best professionals</p>
        <ul class="list-unstyled list">
          <li class="d-inline-block">
            <span>INNOVATION</span>
          </li>
          <li class="d-inline-block">
            <span class="mx-1">•</span>
            <span>COMMITMENT</span>
          </li>
          <li class="d-inline-block">
            <span class="mx-1">•</span>
            <span>EXCELLENCE</span>
          </li>
        </ul>
      </span>
    </div>
  </b-col>
</template>
<script>
export default {
  name: "LeftSide",
};
</script>
<style lang="scss" scoped>
.principal-text {
  font-size: 4.3vw;
  letter-spacing: 0.1rem;
  font-weight: 100;
  line-height: 2.9vw;
}

.secondary-text {
  font-size: 4.7vw;
  letter-spacing: 0.1rem;
  font-weight: 800;
  line-height: 5.9vw;
}

.second-text {
  font-size: 2vw;
  letter-spacing: 0.1rem;
}

.list {
  margin-top: 2.5vw;
  font-weight: 600;
  font-size: 1.3vw;
  letter-spacing: 0;
}
.login-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>