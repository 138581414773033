<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo" to="/">
        <!-- <BigAmgPrideIcon class="brand-logo-pride" /> -->
        <img
          id="logo"
          :src="require('@/assets/images/logo/amg_new_logo.svg')"
          alt="logo"
          class="logo-login"
        />
      </b-link>

      <LeftSide />

      <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-link class="justify-content-center text-center my-2 center-logo">
            <!-- <BigAmgPrideIcon class="brand-logo-pride" /> -->
            <img
              :src="require('@/assets/images/logo/amg_new_logo.svg')"
              alt="logo"
            />
          </b-link>

          <b-card-title
            class="mb-1 text-center text-primary title-login"
            title-tag="h2"
          >
            Welcome to AMG SOFT
          </b-card-title>
          <b-card-text class="mb-2 text-center" style="font-size: 1.2rem">
            <slot name="title"></slot>
          </b-card-text>

          <slot name="form"></slot>

          <!-- 
          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->
          <SocialNetworks />
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate"
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue"
import SocialNetworks from "@/views/pages/authentication/components/SocialNetwork.vue"
import LeftSide from "@/views/pages/authentication/components/LeftSide.vue"

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    SocialNetworks,
    LeftSide,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import url("https://fonts.cdnfonts.com/css/phetsarath");

.captcha-dark {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.bg-login::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.text-login {
  position: relative;
  z-index: 1;
  color: #d9d9d9;
  text-align: center;
  padding: 0 1rem;
  font-family: "Helvetica", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

[dir] .input-group:not(.bootstrap-touchspin):hover .form-control,
[dir] .input-group:not(.bootstrap-touchspin):hover .input-group-text {
  border-color: #0090e7 !important;
}

[dir] .form-control:hover {
  border-color: #0090e7 !important;
  // border: 1px solid #0090e7 !important;
}

.title-login {
  font-size: 2.5rem;
  font-weight: 900;
}

.label-login {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

.logo-login {
  margin-left: 7.5rem;
  margin-top: 3.5rem;
}

.center-logo {
  display: none;
}

.brand-logo-pride {
  width: 90px !important;
  height: 132px !important;
  margin-left: 7.5rem;
  margin-top: 3.5rem;
}

// hide id="logo" when viewport is less than 988px
@media (max-width: 991px) {
  #logo {
    display: none;
  }
  .center-logo {
    display: block;
  }

  .title-login {
    font-size: 2rem;
  }
}
</style>
